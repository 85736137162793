<template>

  <v-container fluid style="height: 100%">

    <v-dialog v-model="pwDialog" max-width="380" style="z-index:17">
      <v-card>
        <v-card-title class="text-h5"> 비밀번호를 입력해주세요 </v-card-title>

        <v-card-text>
          <v-text-field
            autofocus
            v-model="password"
            type="password"
            name="input-10-1"
            label="비밀번호"
            hint="가입시 등록한 비밀번호를 입력해주세요"
            @keydown.enter="login"
          ></v-text-field>
          <p class="text-subtitle mt-5"> * 비밀번호 분실시 ☎2394로 연락 바랍니다. </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="pwDialog = false">
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert
      type="error"
      :class="$vuetify.breakpoint.name == 'xs' ? 'mt-5 mr-5' : 'mr-5 mb-5'"
      :style="$vuetify.breakpoint.name == 'xs' ? 'position: absolute; top: 0; z-index: 999' : 'position: absolute; right: 0; bottom: 0; z-index: 999'"
      :value="error"
      transition="fade-transition"
    >
      {{ errorMsg }}
    </v-alert>
    <v-row no-gutters style="height: 100%;z-index: 16;position:relative" justify="center" align="center" >
      <v-col md="3" xs="12">
        <v-expand-x-transition>
          <v-card elevation="5" :loading="loading">
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="5"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-row justify="center" align="center" no-gutters class="pt-5">
              <v-col cols="6" class="pl-1">
                <v-img src="/images/logo.jpg" width="300" />
              </v-col>
            </v-row>
            <!-- 로그인 -->
            <v-slide-y-transition>
              <v-card-text class="pt-10" v-show="loginDialog">
                <v-form @submit.prevent="check_user">
                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                    class="pl-10 pr-10"
                  >
                    <v-text-field
                      v-model="sabun"
                      label="행번"
                      :rules="sabun_rule"
                      hide-details="auto"
                    ></v-text-field>
                  </v-row>
                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                    class="pl-10 pr-10"
                  >
                    <v-text-field
                      label="이름"
                      :rules="name_rule"
                      v-model="name"
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-row>
                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                    class="mt-10 pl-10 pr-10"
                  >
                    <v-btn
                      style="width: 100%"
                      large
                      color="secondary"
                      type="submit"
                      depressed
                      :disabled="
                        sabun.length == 7 && name.length > 1 ? false : true
                      "
                      >로그인
                    </v-btn>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-slide-y-transition>

            <!-- 회원가입 -->

            <v-card-text class="pt-10" v-if="registerDialog">
              <v-row no-gutters align="center" justify="center">
                <p class="text-caption">프로필 이미지(변경시 클릭)</p>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                <v-avatar
                  size="128"
                  @click="$refs.imageInput.$refs.input.click()"
                  style="cursor: pointer;"
                >
                  <img src="/profileImages/default.jpg" ref="pfImage" />
                </v-avatar>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                <v-col cols="6">
                  <v-file-input
                    accept="image/png, image/jpeg, image/bmp"
                    style="visibility: hidden; width: 0; height: 0"
                    ref="imageInput"
                    @change="onUploadImage"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                align="center"
                class="mt-2 pl-10 pr-10"
                justify="center"
              >
                <v-text-field
                  v-model="name"
                  :rules="name_rule"
                  label="블로그에서 사용할 이름"
                  hint="본인을 잘 나타낼 수 있는 닉네임"
                  hide-details="auto"
                ></v-text-field>
              </v-row>

              <v-row
                no-gutters
                align="center"
                justify="center"
                class="mt-2 pl-10 pr-10"
              >
                <v-text-field
                  v-model="password"
                  :rules="password_rule"
                  type="password"
                  name="input-10-1"
                  label="비밀번호 입력"
                  hint="최소 8자리 입력"
                  counter
                ></v-text-field>
              </v-row>
              <v-row
                no-gutters
                align="center"
                justify="center"
                class="pl-10 pr-10"
              >
                <v-text-field
                  v-model="password2"
                  :rules="password_rule2"
                  type="password"
                  name="input-10-1"
                  label="비밀번호 재 입력"
                  hint="비밀번호 재 입력"
                  counter
                ></v-text-field>
              </v-row>

              <v-slide-y-transition>
                <v-row
                  no-gutters
                  align="center"
                  justify="center"
                  class="mt-5"
                  v-show="registerDrag"
                >
                  <drag-verify
                    :width="slideVerify['width']"
                    :height="slideVerify['height']"
                    :text="slideVerify['text']"
                    :color="slideVerify['color']"
                    :success-text="slideVerify['success-text']"
                    :background="slideVerify['background']"
                    :progress-bar-bg="slideVerify['progress-bar-bg']"
                    :completed-bg="slideVerify['completed-bg']"
                    :handler-bg="slideVerify['handler-bg']"
                    :text-size="slideVerify['text-size']"
                    :circle="slideVerify['circle']"
                    v-on:passcallback="register"
                  />
                </v-row>
              </v-slide-y-transition>

              <!-- <v-btn
                style="width: 100%"
                large
                color="secondary"
                @click="login"
                depressed
                :disabled="sabun.length == 7 && name.length > 1 ? false : true"
                > 
              </v-btn> -->
            </v-card-text>
          </v-card>
        </v-expand-x-transition>
      </v-col>
    </v-row>
        <div class="waveWrapper waveAnimation">
  <div class="waveWrapperInner bgTop">
    <div class="wave waveTop" style="background-image: url('http://front-end-noobs.com/jecko/img/wave-top.png')"></div>
  </div>
  <div class="waveWrapperInner bgMiddle">
    <div class="wave waveMiddle" style="background-image: url('http://front-end-noobs.com/jecko/img/wave-mid.png')"></div>
  </div>
  <div class="waveWrapperInner bgBottom">
    <div class="wave waveBottom" style="background-image: url('http://front-end-noobs.com/jecko/img/wave-bot.png')"></div>
  </div>
</div>
  </v-container>
  
</template>

<script>
import dragVerify from "vue-drag-verify";

export default {
  name: "Login",
  components: {
    dragVerify,
  },
  data: function () {
    return {
      sabun_rule: [
        (v) => !!v || "사번은 필수 입력사항입니다",
        // eslint-disable-next-line no-unused-vars
        (v) => /^[0-9]*$/ || "행번은 숫자만 입력 가능합니다.",
        (v) => !(v && v.length != 7) || "행번은 7자리 입니다",
      ],
      name_rule: [(v) => !!v || "이름은 필수 입력사항입니다"],
      password_rule: [
        (v) => !!v || "비밀번호는 필수 항목입니다",
        // eslint-disable-next-line no-unused-vars
        (v) => !(v && v.length < 8) || "비밀번호 최소 길이는 8자리입니다",
      ],
      password_rule2: [
        (v) => !!v || "비밀번호는 필수 항목입니다",
        // eslint-disable-next-line no-unused-vars
        (v) => !(v != this.password) || "입력한 비밀번호와 일치하지 않습니다",
      ],
      sabun: "",
      name: "",
      error: false,
      errorMsg: "",
      loading: false,
      loginDialog: true,
      registerDialog: false,
      password: "",
      password2: "",
      upload_image_file:null,
      slideVerify: {
        width: 300,
        height: 60,
        text: "밀어서 회원가입",
        "success-text": "완료되었습니다!",
        background: "#CCCCCC",
        color: "#ffffff",
        "progress-bar-bg": "#8890FB",
        "completed-bg": "#66cc66",
        "handler-bg": "#FFFFFF",
        "text-size": "15px",
        circle: true,
      },
      pwDialog: false,
    };
  },
  methods: {
    login: function () {
      let self = this;

      if(self.password.length==0){
        alert("비밀번호를 입력해주세요")
        return;
      }


      let data = {
        sabun: self.sabun,
        pw: self.password,
      };
      this.$axios
        .post(this.$backEndUrl + "/login", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          data = response.data;
          console.log(data)
          if (data["isSuccess"]) {
            localStorage.setItem("token", data["token"]);
            localStorage.setItem("profile", data["profile_image"]);
            localStorage.setItem("name", data["name"]);
            localStorage.setItem("id", data["id"]);
            localStorage.setItem("dept", data["dept"]);
            localStorage.setItem("isAdmin", data["isAdmin"]);

            self.$emit("loginSuccess",data);


            self.pwDialog = false;
            self.$router.replace("/");
          } else {
            self.errorMsg = "비밀번호가 잘못되었습니다";
            self.showErrDialog();
          }
        });
    },
    onUploadImage: function (file) {
      this.upload_image_file = file;
      console.log(this.upload_image_file);
      this.$refs.pfImage.src = URL.createObjectURL(this.upload_image_file);
    },
    check_user: function () {
      let self = this;

      self.loading = true;

      let data = {
        sabun: self.sabun,
        name: self.name,
      };
      this.$axios
        .post(this.$backEndUrl + "/checkDgb", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          self.loading = false;
          data = response.data;
          if (!data["isDgbUser"]) {
            self.errorMsg = "대구은행 ICT본부/정보보호부 직원이 아닙니다";
            self.showErrDialog();
            return;
          }

          if (!data["isRegistred"]) {
            self.loginDialog = false;
            self.registerDialog = true;
            return;
          }

          this.pwDialog = true;
        });
    },
    register: function () {

      let self = this;
      if (!this.name || !this.password || this.password.length <= 7 || this.password!=this.password2) {
        this.errorMsg = "입력값을 확인해 주세요";
        this.showErrDialog();
        return;
      }


      self.uploadImage().then((response) => {
        //let self = this;
        let profileImagePath = "";
        if (response == false) {
          profileImagePath = "/profileImages/default.jpg"
        } else {
          profileImagePath = "/profileImages/" + response["fileName"];
        }

        localStorage.setItem("profile", profileImagePath);

        self.$profileImage = profileImagePath;

        let data = {
          profile_image: profileImagePath,
          sabun: self.sabun,
          name: self.name,
          email: "test@test.com",
          pw: self.password,
        };

        this.$axios
          .post(this.$backEndUrl + "/register", JSON.stringify(data), {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            data = response.data;
            this.login();
          });
      });

    },
    uploadImage: async function () {
      let self = this;
      if (!self.upload_image_file) {
        console.log("업로드 이미지 없음");
        return "";
      }
      let formData = new FormData();
      formData.append("file", self.upload_image_file);
      formData.append("isProfile", true);
      let res = this.$axios
        .post(this.$backEndUrl + "/uploadImage", formData)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
      return res;
    },
    showErrDialog: function () {
      if (!this.error) {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 3000);
      }
    },
  },
  computed: {
    registerDrag: function () {
      if (this.name.length > 0 && this.password.length >= 8) {
        if (this.password == this.password2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {},
  mounted() {
    this.$emit("pageChange", "Login");
  },
};
</script>

<style>
@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}
.waveWrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}
.waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom: -1px;
    background-image: linear-gradient(to top, #86377b 20%, #27273c 80%);
}
.bgTop {
    z-index: 15;
    opacity: 0.5;
}
.bgMiddle {
    z-index: 10;
    opacity: 0.75;
}
.bgBottom {
    z-index: 5;
}
.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom;
}
.waveTop {
    background-size: 50% 100px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
   -webkit-animation: move-wave 3s;
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
}
.waveMiddle {
    background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
    animation: move_wave 10s linear infinite;
}
.waveBottom {
    background-size: 50% 100px;
}
.waveAnimation .waveBottom {
    animation: move_wave 15s linear infinite;
}
</style>
