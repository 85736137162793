
<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="position: fixed; top: 0; left: 0; overflow-y: scroll"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item @click="goPage('/')">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
              <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item @click="goPage('/about/')">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
              <v-list-item-title>About</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      prominent
      color="white"
      hide-on-scroll
      v-if="showAppBar"
      scroll-threshold="10"
      height="80"
      style="z-index: 3"
    >
      <div class="d-flex align-center mt-5">
        <router-link to="/" style="text-decoration: none">
          <v-img
            :class="$vuetify.breakpoint.name == 'xs' ? 'ml-1' : 'ml-10'"
            src="/images/logo.jpg"
            transition="scale-transition"
            :width="$vuetify.breakpoint.name == 'xs' ? '160' : '200'"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon
        @click="drawer = true"
        class="mt-3"
        v-if="$vuetify.breakpoint.xs"
      ></v-app-bar-nav-icon>

      <div class="d-flex align-center mt-5" v-if="!$vuetify.breakpoint.xs">
        <router-link to="/" style="color: black">
          <button>Home</button>
        </router-link>
      </div>

      <div
        class="d-flex align-center mt-5 ml-15"
        v-if="!$vuetify.breakpoint.xs"
      >
        <router-link to="/write" style="color: black">
          <button >Write</button>
        </router-link>
      </div>

      <div
        class="d-flex align-center mt-5 ml-15"
        v-if="!$vuetify.breakpoint.xs"
      >
        <router-link to="/about" style="color: black">
          <button >About</button>
        </router-link>
      </div>

      <div
        class="d-flex align-center mt-3 ml-15 mr-5"
        v-if="!$vuetify.breakpoint.xs"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" large plain> {{ name }}({{ dept }}) </v-btn>
            <v-badge
              v-show="alarmCnt > 0"
              color="red"
              :content="alarmCnt"
              overlap
            >
              <v-avatar size="45" v-on="on" style="cursor: pointer">
                <img :src="profileImage" ref="pf" />
              </v-avatar>
            </v-badge>
            <v-avatar
              v-show="alarmCnt == 0"
              size="45"
              v-on="on"
              style="cursor: pointer"
            >
              <img :src="profileImage" ref="pf" />
            </v-avatar>
          </template>
          <v-list>
            <v-list-item
              link
              @click="getSavedArticleList()"
              v-show="alarmCnt > 0"
            >
              <v-badge color="red" :content="alarmCnt" inline>
                <v-list-item-title> 임시저장 게시글 </v-list-item-title>
              </v-badge>
            </v-list-item>
            <v-list-item link @click="openUserInfoDialog()">
              <v-list-item-title> 개인정보 변경</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="logout">
              <v-list-item-title> 로그아웃</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-btn icon>
          <v-avatar size="45">
            <img :src="profileImage" />
          </v-avatar>
        </v-btn> -->
      </div>
    </v-app-bar>

    <v-alert
      type="success"
      class="mr-5 mb-5"
      style="position: fixed; right: 0; bottom: 0; z-index: 999"
      :value="showAlert"
      transition="fade-transition"
    >
      {{ alertMsg }}
    </v-alert>
    <v-dialog max-width="500" v-model="savedArticleDialog">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          임시저장 게시글
        </v-card-title>
        <v-card-text class="pt-10">
          <v-list>
            <v-list-item
              link
              v-for="article in savedArticleList"
              :key="article.id"
              @click="goToWrite(article.id)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    article.title.length == 0 ? "(제목없음)" : article.title
                  }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  {{ article.content_text.substring(0, 20) }}...
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>{{
                  article.modified_datetime
                }}</v-list-item-action-text>
                <v-icon> mdi-clock-time-nine-outline </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="editDialog">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          내 프로필 수정하기
        </v-card-title>
        <v-card-text class="pt-10">
          <v-row no-gutters align="center" justify="center">
            <p class="text-caption">프로필 이미지(변경시 클릭)</p>
          </v-row>
          <v-row no-gutters align="center" justify="center">
            <v-avatar size="128" @click="$refs.imageInput.$refs.input.click()">
              <img :src="user_info.profile_image" ref="pfImage" />
            </v-avatar>
          </v-row>
          <v-row no-gutters align="center" justify="center">
            <v-col cols="6">
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                style="visibility: hidden; width: 0; height: 0"
                ref="imageInput"
                @change="onUploadImage"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            align="center"
            class="mt-2 pl-10 pr-10"
            justify="center"
          >
            <v-text-field
              v-model="change_name"
              :rules="name_rule"
              label="블로그에서 사용할 이름"
              hint="본인을 잘 나타낼 수 있는 닉네임"
              hide-details="auto"
            ></v-text-field>
          </v-row>

          <v-row
            no-gutters
            align="center"
            justify="center"
            class="mt-2 pl-10 pr-10"
          >
            <v-checkbox
              style="width: 100%"
              v-model="changePw"
              label="비밀번호 변경"
              color="indigo"
              hide-details
            ></v-checkbox>

            <v-expansion-panels v-model="pwPanel" class="mt-5">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  비밀번호 변경
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="change_pw"
                    :rules="password_rule"
                    type="password"
                    name="input-10-1"
                    label="변경할 비밀번호 입력"
                    hint="최소 8자리 입력"
                    counter
                  ></v-text-field>
                  <v-text-field
                    v-model="change_pw2"
                    :rules="password_rule2"
                    type="password"
                    name="input-10-1"
                    label="비밀번호 재 입력"
                    hint="비밀번호 재 입력"
                    counter
                  ></v-text-field>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="6" class="d-flex justify-center">
              <v-btn color="error" @click="editDialog = false"> 취소 </v-btn>
            </v-col>
            <v-col cols="6" class="d-flex justify-center">
              <v-btn color="priamary" @click="changeUserInfo"> 적용 </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Main
      style="height: 100%"
      v-on:pageChange="onPageChange"
      v-on:loginSuccess="onLoginSuccess"
    />
  </v-app>
</template>

<script>
import Main from "./components/Main.vue";

export default {
  name: "App",

  components: {
    Main,
  },
  computed: {
    showBadge: function () {
      if (this.alarmCnt > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goPage: function(name){
      this.drawer = false;
      this.$router.push(name);
    },
    onPageChange: function (pageName) {
      this.getSavedArticleListCnt();

      switch (pageName) {
        case "Login":
          // app-bar 숨기기
          this.aboutOverlay = false;
          this.showAppBar = false;
          console.log(this.name, this.dept, this.profileImage);
          break;
        case "ArticleView":
          this.aboutOverlay = false;
          this.showAppBar = true;
          console.log(this.name, this.dept, this.profileImage);
          break;
        case "ArticleList":
          this.aboutOverlay = false;
          this.showAppBar = true;
          console.log(this.name, this.dept, this.profileImage);
          break;
        case "ArticleWrite":
          this.aboutOverlay = false;
          this.showAppBar = true;
          console.log(this.name, this.dept, this.profileImage);
          break;
        case "About":
          this.aboutOverlay = false;
          this.showAppBar = true;
          console.log(this.name, this.dept, this.profileImage);
          break;
      }
    },
    onLoginSuccess: function (data) {
      this.name = data["name"];
      this.dept = data["dept"];
      this.profileImage = data["profile_image"];
      console.log(this.profileImage);
      this.getSavedArticleListCnt();
    },
    logout: function () {
      localStorage.setItem("token", null);
      localStorage.setItem("id", null);
      localStorage.setItem("name", null);
      localStorage.setItem("dept", null);
      this.$router.replace("/login");
    },
    getSavedArticleListCnt: function () {
      let self = this;
      self.$axios
        .post(self.$backEndUrl + "/getSavedArticleCnt", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          let data = response.data;
          self.alarmCnt = data["article_cnt"];
        });
    },
    openUserInfoDialog: function () {
      let self = this;
      self.editDialog = true;
      self.$axios
        .post(self.$backEndUrl + "/getUserInfo", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          self.user_info = response.data;
          self.change_name = self.user_info.name;
        });
    },
    onUploadImage: function (file) {
      this.upload_image_file = file;
      console.log(this.upload_image_file);
      this.$refs.pfImage.src = URL.createObjectURL(this.upload_image_file);
    },
    changeUserInfo: function () {
      let self = this;

      if (self.changePw) {
        if (self.change_pw.length < 8 || self.change_pw2.length < 8) {
          this.showAlertDialog("비밀번호를 확인하여 주세요");
          return;
        }
        if (self.change_pw != self.change_pw2) {
          this.showAlertDialog("비밀번호를 확인하여 주세요");
          return;
        }
      }
      if (self.change_name.length == 0) {
        this.showAlertDialog("이름을 정확히 입력해주세요");
        return;
      }

      self.uploadImage().then((response) => {
        //let self = this;
        let profileImagePath = "";
        if (response == false) {
          profileImagePath = self.profileImage;
          console.log("test");
        } else {
          profileImagePath = "/profileImages/" + response["fileName"];
        }

        localStorage.setItem("profile", profileImagePath);
        console.log(profileImagePath);

        let data = {
          profile_image: profileImagePath,
          name: self.change_name,
          email: "test@test.com",
          changePw: self.changePw,
          pw: self.change_pw,
        };
        console.log(JSON.stringify(data));

        this.$axios
          .post(this.$backEndUrl + "/changeUserInfo", JSON.stringify(data), {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            data = response.data;
            self.editDialog = false;
            self.$refs.pf.src = profileImagePath;
            self.showAlertDialog("정상적으로 변경되었습니다.");
          });
      });
    },

    uploadImage: async function () {
      let self = this;
      if (!self.upload_image_file) {
        console.log("업로드 이미지 없음");
        return "";
      }
      let formData = new FormData();
      formData.append("file", self.upload_image_file);
      formData.append("isProfile", true);
      let res = this.$axios
        .post(this.$backEndUrl + "/uploadImage", formData)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
      return res;
    },
    showAlertDialog: function (msg) {
      if (!this.showAlert) {
        this.showAlert = true;
        this.alertMsg = msg;
        setTimeout(() => {
          this.showAlert = false;
        }, 1000);
      }
    },
    getSavedArticleList: function () {
      let self = this;

      this.$axios
        .post(this.$backEndUrl + "/getSavedArticle", null, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          let data = response.data;
          self.savedArticleList = data["article_list"];
          self.savedArticleDialog = true;
        });
    },
    goToWrite: function (id) {
      this.savedArticleDialog = false;
      console.log("temp 게시글 id", id);
      this.$router.push({ name: "write", params: { tempId: id } });
    },
  },

  data: function () {
    return {
      drawer: false,
      showAppBar: true,
      editDialog: false,
      password_rule: [
        (v) => !!v || "비밀번호는 필수 항목입니다",
        // eslint-disable-next-line no-unused-vars
        (v) => !(v && v.length < 8) || "비밀번호 최소 길이는 8자리입니다",
      ],
      password_rule2: [
        (v) => !!v || "비밀번호는 필수 항목입니다",
        // eslint-disable-next-line no-unused-vars
        (v) => !(v != this.change_pw) || "입력한 비밀번호와 일치하지 않습니다",
      ],
      name_rule: [(v) => !!v || "이름은 필수 입력사항입니다"],
      user_info: {},
      upload_image_file: null,
      change_name: "",
      change_pw: "",
      change_pw2: "",
      changePw: false,
      pwPanel: null,
      profileImage: "",
      name: "",
      dept: "",
      showAlert: false,
      alertMsg: "",
      aboutOverlay: false,
      alarmCnt: 0,
      savedArticleDialog: false,
      savedArticleList: [],
    };
  },
  watch: {
    changePw: function () {
      if (this.changePw) {
        this.pwPanel = 0;
      } else {
        this.pwPanel = null;
      }
    },
  },
  mounted: function () {
    console.log("App mounted");
    // 새로고침시에 사용자 정보 리셋
    this.name = localStorage.getItem("name");
    this.profileImage = localStorage.getItem("profile");
    this.dept = localStorage.getItem("dept");
    this.getSavedArticleListCnt();
  },
};
</script>

<style>
@import "./assets/styles/default.min.css";
</style>
