<template>
  <v-container flex id="articleList" class="pt-10">
    <vue-scroll-progress-bar
      @complete="handleComplete"
      height=".5rem"
      backgroundColor="linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%)"
       ref="test"
    />
    <v-alert
      type="success"
      class="mr-5 mb-5"
      style="position: fixed; right: 0; bottom: 0; z-index: 999"
      :value="isComplete"
      transition="fade-transition"
     
    >
      게시글을 모두 다 읽으셨습니다. 댓글 작성도 해볼까요?
    </v-alert>

    <v-alert
      type="success"
      class="mr-5 mb-5"
      style="position: fixed; right: 0; bottom: 0; z-index: 999"
      :value="showAlert"
      transition="fade-transition"
    >
      {{ alertMsg }}
    </v-alert>

    <p class="text">Read Post</p>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col md="9" xs="12" >
        <v-container class="pt-10">
          <v-row dense align="center">
            <v-col cols="1">
              <v-avatar size="40">
                <img :src="authorImage" />
              </v-avatar>
            </v-col>

            <v-col cols="9" align-self="center" :class="$vuetify.breakpoint.name == 'xs' ? 'pl-6' : ''">
              <p :class="$vuetify.breakpoint.name == 'xs' ? 'text-caption align-center' : 'text-subtitle-2 align-center'">
                {{ author }} ＊ {{ frmtCreateDate }}
              </p>
            </v-col>
            <v-spacer/>
            <v-col cols="1" align-self="center" v-if="isModifyAllow && !($vuetify.breakpoint.name == 'xs')">
              <v-btn icon 
              x-large
              @click="editArticle" >
                <v-icon :size="$vuetify.breakpoint.name == 'xs' ? '20' : '30'">mdi-pencil-box-multiple</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="1" align-self="center">
              <v-btn 
              icon 
              :x-large="!$vuetify.breakpoint.name == 'xs'" 
              @click="like(id, isLiked)">
                  <v-icon size='30' :color="isLiked ? 'red' : 'grey'"
                    >mdi-heart</v-icon
                  >
                  <p class="text-subtitle-2 ml-1 pt-3">{{ likeCnt }}</p>
              </v-btn>
            </v-col>

          </v-row>
          <v-row>
            <v-divider></v-divider>
          </v-row>
          <v-row>
            <v-spacer />
            <p :class="$vuetify.breakpoint.name == 'xs' ? 'text-caption align-center' : 'text-subtitle-2'">조회수&nbsp;{{ viewCnt }}</p>
            <p :class="$vuetify.breakpoint.name == 'xs' ? 'text-caption align-center ml-3' : 'text-subtitle-2 ml-3'">댓글&nbsp;{{ cmtCnt }}</p>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col md="9" xs="12">
        <h1>{{ title }}</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="9" xs="12">
        <div
          class="d-flex ql-container ql-snow"
          style="border: none"
        >
          <div v-html="content" class="ql-editor" />
        </div>
      </v-col>
    </v-row>
    <v-container class="pt-10"> </v-container>

    <v-row justify="center">
      <v-col md="9" xs="12">
        <comment-list v-on:alert="onAlert" v-if="id" v-bind:articleId="id"></comment-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommentList from "./CommentList.vue";

export default {
  name: "ArticleView",
  components: {
    CommentList,
  },
  data: function () {
    return {
      id: "",
      title: "",
      author: "",
      author_id:"",
      authorImage: "",
      content: "",
      createDate: "",
      isLiked: "",
      isComplete: false,
      isCompleteOnce:false,
      showAlert: false,
      alertMsg: "",
      viewCnt: 0,
      cmtCnt: 0,
      likeCnt: 0,
      scrollOnce: false,
    };
  },
  computed:{
    pageHeight() {
      return document.body.scrollHeight;
    },
    isModifyAllow(){
      return ((localStorage.getItem("isAdmin")=='true')||(this.author_id==parseInt(localStorage.getItem('id'))))
    },
    frmtCreateDate(){
      return this.$getFormatedDateString(this.createDate);
    }
  },
  methods: {
    showArticleDetail: function (val) {
      alert("게시글 상세보기 " + val);
    }, 

    getArticle: function (id) {
      let self = this;
      let data = {
        articleId: id,
      };
      this.$axios
        .post(this.$backEndUrl  + "/getArticle", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          data = response.data;
          self.id = data.id;
          self.title = data.title;
          self.author = data.author;
          self.author_id = data.author_id
          self.authorImage = data.author_image;
          self.content = data.content;
          self.postProcess(data.content)
          self.createDate = data.create_datetime;
          self.isLiked = data.isLiked;
          self.likeCnt = data.likeCnt;
          self.viewCnt = data.viewCnt;
          self.cmtCnt = data.cmtCnt;
        });
    },
    // 이미지 뷰어 
    postProcess: function(data){
      let  el = document.createElement( 'html' );
      el.innerHTML = data;
      let els = el.getElementsByTagName("img");
      for(let i=0; i< els.length ; i++){
        els[i].setAttribute('onclick','this.test()');
        //els[i].tyle+="cursor: pointer";
        // console.log(els[i].src)
      }
      this.content = el.innerHTML;

    },

    test:function(){
      alert('11');
    },

    handleComplete: function () {
      if(!this.isCompleteOnce){
        if (!this.isComplete) {
          this.isComplete = true;
          setTimeout(() => {
            this.isComplete = false;
          }, 3000);
        }
        this.isCompleteOnce = true;
      }
    },
    like: function (articleId, isLiked) {
      let self = this;
      let data = {
        articleId: articleId,
        isLike: isLiked ? false : true,
      };
      this.$axios
        .post(self.$backEndUrl + "/like", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data["isSuccess"]) {
            if (response.data["msg"] == "like") {
              self.showAlertDialog("Like+ 하였습니다!");
              self.likeCnt += 1;
              self.isLiked = true;
            } else {
              self.showAlertDialog("Like를 취소하였습니다");
              self.likeCnt -= 1;
              self.isLiked = false;
            }
          } else {
            self.showAlertDialog("서버와 통신중 에러 발생");
          }
        });
    },
    showAlertDialog: function (msg) {
      if (!this.showAlert) {
        this.showAlert = true;
        this.alertMsg = msg;
        setTimeout(() => {
          this.showAlert = false;
        }, 1000);
      }
    },
    onAlert:function(msg){
      this.showAlertDialog(msg);
    },
    editArticle: function(){
      this.$router.replace("/write/" + this.id);
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    console.log('mounted')
    this.getArticle(this.id);
    this.$emit("pageChange", "ArticleView");
  },

  updated(){
    console.log('updated')

  }
};
</script>
