<template>
  <v-container class="mt-5">
    <v-container style="z-index: 3; position: relative">
      <v-row justify="center" align="center" dense>
        <v-col md="8" xs="12">
          <v-sheet
            color="rgba(0, 0, 0, 0.3)"
            elevation="1"
            rounded
            :height="
              $vuetify.breakpoint.name == 'xs'
                ? '45px'
                : '70px'
            "
            shaped
          >
           <p
            :class="
              $vuetify.breakpoint.name == 'xs'
                ? 'text-h4 font-weight-bold white--text'
                : 'text-h2 font-weight-bold white--text'
            "
            align="center"
          >
            DGB IT R&D
          </p>
          </v-sheet>
         
        </v-col>
      </v-row>

      <v-row justify="center" align="center" dense no-gutters class="mt-5">
        <v-img src="/images/about.jpg" max-width="500" />
      </v-row>

      <v-row justify="center" align="center">
        <v-col md="8" xs="12">
          <v-sheet
            color="rgba(0, 0, 0, 0.3)"
            elevation="1"
            rounded

            shaped
          >
          <p class="text-h6 pa-3 white--text" align="center">
            In order to actively respond to the era of the 4th industrial
            revolution, DGB Digital IT R&D has been operated through
            public offerings by employees every year since 2016, and aims to
            research/analysis business models that can be realized by
            incorporating new ICT technologies into finance.
          </p>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <div class="waveWrapper waveAnimation">
      <div class="waveWrapperInner bgTop">
        <div
          class="wave waveTop"
          style="
            background-image: url('http://front-end-noobs.com/jecko/img/wave-top.png');
          "
        ></div>
      </div>
      <div class="waveWrapperInner bgMiddle">
        <div
          class="wave waveMiddle"
          style="
            background-image: url('http://front-end-noobs.com/jecko/img/wave-mid.png');
          "
        ></div>
      </div>
      <div class="waveWrapperInner bgBottom">
        <div
          class="wave waveBottom"
          style="
            background-image: url('http://front-end-noobs.com/jecko/img/wave-bot.png');
          "
        ></div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "About",
  components: {},
  data: function () {
    return {};
  },
  methods: {
    test: function () {},
  },
  computed: {},
  watch: {},
  mounted() {
    this.$emit("pageChange", "About");
  },
};
</script>
<style>
@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
.waveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  margin: auto;
}
.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
  background-image: linear-gradient(to top, #86377b 20%, #27273c 80%);
}
.bgTop {
  z-index: 15;
  opacity: 0.5;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}
.bgBottom {
  z-index: 5;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 50% 100px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 100px;
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}
</style>
