export default{

    install(Vue){
        Vue.prototype.$test = function(){
            alert('test')
        }

        Vue.prototype.$getFormatedDateString = function(dateString){
            let date = new Date(Date.parse(dateString.replaceAll('GMT','')))
            let month = ''+(date.getMonth()+1);
            let day= ''+date.getDate();
            let year = ''+ date.getFullYear();
            let hour = '' +date.getHours();
            let min = ''+date.getMinutes();
            
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
            if (hour.length < 2) 
                hour = '0' + hour;
            if (min.length < 2) 
                min = '0' + min;
            return year+"."+month+"."+day+" "+hour+":"+min
        }
    }
}