import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from "axios";

 //https://webisfree.com/2019-03-25/[vuejs]-vue-router-%EC%82%AC%EC%9A%A9%ED%95%98%EB%8A%94-%EB%B0%A9%EB%B2%95-%EB%9D%BC%EC%9A%B0%ED%8A%B8-%EC%84%A4%EC%A0%95

// Vue.use(router)


Vue.config.productionTip = false

// quill 사용
// https://github.com/surmon-china/vue-quill-editor
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import common from './assets/js/common'
Vue.use(VueQuillEditor)

Vue.use(common)

import VueScrollProgressBar from '@guillaumebriday/vue-scroll-progress-bar'

Vue.use(VueScrollProgressBar)
//Vue.prototype.$backEndUrl = 'https://dgbitrnd.co.kr:8080'
Vue.prototype.$backEndUrl = 'https://itrndblog.duckdns.org/api'
Vue.prototype.$axios = axios

new Vue({
  vuetify,router,
  render: h => h(App),
  beforeCreate: function(){
    console.log(this.$backEndUrl)
  },
  created: function(){
   
  }
  
}).$mount('#app')
