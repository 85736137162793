import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

if (window.document.documentMode) {
    alert('Edge 브라우져나 Chrome 브라우져를 이용해 주세요')
}

export default new Vuetify({
});
