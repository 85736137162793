<template>
  <v-container id="commentList" class="pt-10">
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h6"> 댓글을 삭제하시겠습니까? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            취소
          </v-btn>

          <v-btn color="green darken-1" text @click="deleteComment">
            삭제
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center" class="mb-3">
      <v-col cols="12">
        <p class="text-subtitle-1 font-weight-bold">
          {{ commentList.length }}개의 댓글
        </p>
        <v-textarea
          outlined
          label="댓글 작성하기"
          v-model="cmtWrite"
        ></v-textarea>
        <v-row justify="end">
          <v-btn outlined color="indigo" class="mr-2" @click="registerComment">
            등록하기
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-for="(comment, index) in commentList" :key="index" no-gutters dense class="mt-3">
      
      <div v-if="!$vuetify.breakpoint.xs">
      <v-col v-if="comment.parent_id" cols="1" class="pt-1 pl-6" >
        <v-icon x-large color="green">mdi-arrow-right-bottom</v-icon>
      </v-col>
      </div>

      <div v-else>
      <v-col v-if="comment.parent_id" cols="1" class="pt-1">
        <v-icon color="green">mdi-arrow-right-bottom</v-icon>
      </v-col>
      </div>

      <v-col>
        <v-card shape justify="center" align="center">
          <v-container>
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="1">
                <v-avatar size="70">
                  <img :src="comment.author_image" />
                </v-avatar>
              </v-col>

              <v-col class="pl-15" cols="9">
                <v-container>
                  <v-row align="end">
                    <p class="text-subtitle-1 font-weight-bold">
                      {{ comment.author }}
                    </p>
                  </v-row>
                  <v-row align="center">
                    <p class="text-subtitle-2">
                      {{ $getFormatedDateString(comment.create_datetime) }}
                    </p>
                  </v-row>
                </v-container>
              </v-col>
              <v-spacer />
              <v-col cols="1">
                <v-btn icon @click="selectReply(comment.id, comment.content)">
                  <v-icon size="20" color="primary"
                    >mdi-message-reply-text</v-icon
                  >
                </v-btn>
              </v-col>
              <v-col cols="1" v-if="comment.author_id == userId">
                <v-btn
                  icon
                  @click="
                    dialog = true;
                    currentCmt = comment.id;
                  "
                >
                  <v-icon size="20" color="red">mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense v-if="comment.parent_id" no-gutters>
              <v-col cols="12">
                <v-sheet color="#FFFDE7" rounded="rounded-lg">
                  <p class="text-subtitle font-italic">
                    <strong>{{ comment.parent_content }} </strong>에 대한 대댓글
                  </p></v-sheet
                >
              </v-col>
            </v-row>
            <v-row align="center" class="pl-5 pr-5 pb-5" no-gutters>
              <p
                class="mr-3 text-display-4 blue--text"
                v-if="comment.parent_id"
              >

              </p>
              {{ comment.content }}
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-alert border="top" color="#64B5F6" dark class="mt-5" v-show="replyShow">
      <v-row>
        <v-col cols="5">
          <p>대댓글 작성</p>
        </v-col>
        <v-spacer />
        <v-col cols="1" class="mr-3">
          <v-btn icon @click="replyShow = false"
            ><v-icon size="20" color="white">mdi-close-box</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <p class="text-caption font-italic">
            "{{ selectCmtContent }}" 에 대한 대댓글
          </p>
        </v-col>
      </v-row>
      <v-row dense>
        <v-textarea
          outlined
          label="대댓글 작성하기"
          v-model="replayCmt"
        ></v-textarea>
      </v-row>
      <v-row justify="end" dense>
        <v-btn outlined color="" class="mr-2" @click="registerReply">
          등록하기
        </v-btn>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
export default {
  name: "CommentList",
  props: ["articleId"],
  data: function () {
    return {
      commentList: [],
      cmtWrite: "",
      dialog: false,
      currentCmt: "",
      selectCmtId: "",
      selectCmtContent: "",
      replyShow: false,
      replayCmt: "",
    };
  },
  computed: {
    pageHeight() {
      return document.body.scrollHeight;
    },
    userId() {
      return localStorage.getItem("id");
    },
  },
  methods: {
    registerComment: function () {
      let self = this;
      console.log(self.replayCmt.length);
      if (self.cmtWrite.length == 0) {
        this.$emit("alert", "댓글 내용을 작성해 주세요");
        return;
      }
      if (self.cmtWrite.length > 255) {
        this.$emit("alert", "댓글은 255자까지 작성 가능합니다");
        return;
      }

      let data = {
        articleId: this.articleId,
        content: this.cmtWrite,
        parentId: null,
      };

      this.$axios
        .post(this.$backEndUrl + "/regComment", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          response;
          self.$emit("alert", "댓글 작성이 완료되었습니다.");
          self.cmtWrite = "";
          self.getCommentList(true);
        });
    },

    registerReply: function () {
      let self = this;
      console.log(self.replayCmt.length);
      if (self.replayCmt.length == 0 || self.replayCmt.length > 255) {
        this.$emit("alert", "댓글 내용을 작성해 주세요");
        return;
      }
      let data = {
        articleId: this.articleId,
        parentId: this.selectCmtId,
        content: this.replayCmt,
      };

      this.$axios
        .post(this.$backEndUrl + "/regComment", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          response;
          self.$emit("alert", "댓글 작성이 완료되었습니다.");
          self.cmtWrite = "";
          self.getCommentList(true);
          self.replyShow = false;
          self.replayCmt = "";
        });
    },
    getCommentList: function (param) {
      let self = this;
      let data = {
        articleId: this.articleId,
      };
      this.$axios
        .post(this.$backEndUrl + "/getComments", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          let cmtList = response.data["comment_list"];

          // 대댓글 순서 정렬
          let parentList = [];
          let replyList = [];

          for (let i = 0; i < cmtList.length; i++) {
            if (cmtList[i]["parent_id"] != null) {
              replyList.push(cmtList[i]);
            } else {
              parentList.push(cmtList[i]);
            }
          }

          for (let i = 0; i < replyList.length; i++) {
            let parentIdx = parentList.findIndex(
              (k) => k["id"] == replyList[i]["parent_id"]
            );
            parentList.splice(parentIdx + 1, 0, replyList[i]);
          }
          this.commentList = parentList;
          console.log(parentList);

          //this.commentList = response.data["comment_list"];

          if (this.$route.params.cmt != undefined) {
            console.log("scroll to end");
            self.isCompleteOnce = true;
            self.$vuetify.goTo(999999);
          }
          if (param) {
            self.isCompleteOnce = true;
            self.$vuetify.goTo(999999);
          }
        });
    },
    selectReply: function (id, cmt) {
      this.replyShow = true;
      this.$vuetify.goTo(999999);
      this.selectCmtId = id;
      this.selectCmtContent = cmt;
    },

    deleteComment: function () {
      let self = this;

      let data = {
        commentId: this.currentCmt,
      };

      this.$axios
        .post(this.$backEndUrl + "/delComment", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          response;
          self.$emit("alert", "댓글을 삭제하였습니다.");
          self.getCommentList();
          self.$vuetify.goTo(self.pageHeight);
          console.log(self.pageHeight);
        });

      self.dialog = false;
    },
  },
  mounted() {
    this.getCommentList();
  },
};
</script>
