/* eslint-disable no-debugger */ /* eslint-disable no-debugger */
<template>
  <v-container id="articleWrite" class="pt-10" fluid>
    <v-alert
      type="success"
      class="ml-5 mb-5"
      style="position: fixed; left: 0; bottom: 0; z-index: 999"
      :value="showAlert"
      transition="fade-transition"
    >
      {{ alertMsg }}
    </v-alert>

    <!-- 미리보기 영역 -->
    <v-dialog
      v-model="previewDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>게시글 미리보기</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="previewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-row justify="center" align="center" class="mt-5">
          <div class="mb-5" style="width: 740px">
            <h1>{{ title }}</h1>
          </div>
        </v-row>
        <v-row justify="center" align="center">
          <div class="ql-container ql-snow" style="width: 800px">
            <div v-html="articleHtml" class="ql-editor" />
          </div>
        </v-row>
      </v-card>
    </v-dialog>

    <v-container class="mt-10" fluid>
      <!-- <v-row>
        <p class="text">Write post</p>
      </v-row>
      <v-row class="mb-10">
        <v-divider></v-divider>
      </v-row> -->

      <v-row align="center" justify="center">
        <v-col md="2" lg="1">
          <v-select
            v-model="categorySelect"
            :items="categoryList"
            item-text="category_name"
            item-value="category_id"
            label="Category"
          ></v-select>
        </v-col>
        <v-col md="7" lg="6">
          <v-text-field
            class="pb-4"
            label="게시글 제목"
            style="font-size: 1.5em"
            height="35px"
            placeholder="게시글 제목을 입력해주세요"
            v-model="title"
            >{{ title }}</v-text-field
          >
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <div class="scroll-container">
          <quill-editor
            style="width: 800px; min-height: 400px"
            ref="myQuillEditor"
            :options="options"
            @change="editorOnChange"
          ></quill-editor>
        </div>
      </v-row>

      <v-row align="center" justify="end">
        <!-- <v-btn
          tile
          color="primary"
          @click="showPreviewDialog(true)"
          class="ml-5"
        >
          <v-icon left> mdi-television </v-icon>
          미리보기
        </v-btn>
        <v-btn tile color="success" @click="writeArticle()">
          <v-icon left> mdi-pencil </v-icon>
          게시하기
        </v-btn> -->
        <v-speed-dial
          :top="false"
          :left="false"
          :bottom="true"
          :right="true"
          direction="top"
          :open-on-hover="true"
          transition="slide-y-reverse-transition"
          fixed
          class="mb-5"
        >
          <template v-slot:activator>
            <v-btn color="blue darken-2" dark x-large>
              <v-icon class="mr-3"> mdi-pencil-plus </v-icon>
              Write post
            </v-btn>
          </template>

          <v-btn dark small color="indigo" x-large @click="writeArticle()">
            <v-icon class="mr-3">mdi-file-document-edit-outline</v-icon>
            게시하기
          </v-btn>
          <v-btn dark small color="#03A9F4" x-large @click="saveArticle()">
            <v-icon class="mr-3">mdi-content-save-all-outline</v-icon>
            임시저장
          </v-btn>
          <v-btn
            x-large
            dark
            small
            color="green"
            @click="showPreviewDialog(true)"
          >
            <v-icon class="mr-3">mdi-monitor-eye</v-icon>
            미리보기
          </v-btn>
          <v-btn dark small color="red" x-large v-if="id && isAdmin">
            <v-icon class="mr-3">mdi-information-outline</v-icon>
            메타정보
          </v-btn>
          <v-btn
            dark
            small
            color="red"
            x-large
            v-if="id"
            @click="deleteArticle"
          >
            <v-icon class="mr-3">mdi-delete</v-icon>
            삭제하기
          </v-btn>
          <v-btn
            dark
            small
            color="red"
            x-large
            v-if="tempId"
            @click="deleteTempArticle(true)"
          >
            <v-icon class="mr-3">mdi-delete</v-icon>
            삭제하기-임시저장게시글
          </v-btn>
        </v-speed-dial>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { Quill } from "vue-quill-editor";
import ImageResize from "quill-image-resize-vue";
import QuillImageDropAndPaste from "quill-image-drop-and-paste"; //https://github.com/chenjuneking/quill-image-drop-and-paste
import { ImageData } from "quill-image-drop-and-paste";
import VideoResize from "quill-video-resize-module";

import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);
Quill.register("modules/VideoResize", VideoResize); //https://github.com/Y-WeCode/quill-video-resize-module

// window.addEventListener('beforeunload',(event)=>{
//   event.preventDefault();
//   event.returnValue='';
// })

export default {
  name: "ArticleWrite",
  data: function () {
    return {
      id: "",
      tempId: "",
      modified_datetime: "",
      previewDialog: false,
      articleHtml: "",
      options: {
        modules: {
          toolbar: {
            container: [
              [{ size: ["large", "huge"] }],
              [{ header: 1 }, { header: 2 }],
              ["bold", "italic", "underline", "strike"],
              [{ color: [] }, { background: [] }],
              [{ script: "super" }, { script: "sub" }],
              [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              [{ direction: "rtl" }, { align: [] }],
              ["link", "image", "video", "formula"],
              ["clean"],
            ],
            handlers: {
              image: this.toolbarImageHandler,
            },
          },
          imageResize: {},
          imageDropAndPaste: {
            handler: this.imageHandler,
          },
          VideoResize: {},
          syntax: {
            highlight: function (text) {
              console.log(text);
              return hljs.highlightAuto(text).value;
            },
          },
        },
        theme: "snow",
        placeholder: "게시글 내용을 작성해 주세요...",
        scrollingContainer: ".scroll-container",
      },
      title: "",
      author: "",
      author_id: "",
      authorImage: "",
      content: "",
      createDate: "",
      isLiked: "",
      likeCnt: "",
      viewCnt: "",
      cmtCnt: "",
      showAlert: false,
      alertMsg: "",
      categoryList:[],
      categorySelect:null,
    };
  },
  methods: {
    unLoadEvent: function (event) {
      //사이트 이탈 방지
      //if (this.canLeaveSite) return;

      event.preventDefault();
      event.returnValue = "test";
    },

    // eslint-disable-next-line no-unused-vars
    editorOnChange: function (data) {
      // console.log(data.html);
    },

    showPreviewDialog: function (isShow) {
      console.log("showDialog", isShow);
      if (isShow) {
        this.articleHtml = this.editor.root.innerHTML;
      }
      this.previewDialog = isShow;
    },

    toolbarImageHandler: function (clicked) {
      let self = this;
      if (clicked) {
        let fileInput = document.querySelector("input.ql-image[type=file]");
        if (fileInput == null) {
          fileInput = document.createElement("input");
          fileInput.setAttribute("type", "file");
          fileInput.setAttribute(
            "accept",
            "image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
          );
          fileInput.classList.add("ql-image");
          fileInput.addEventListener("change", function (e) {
            const files = e.target.files;
            let file;
            if (files.length > 0) {
              file = files[0];
              const type = file.type;
              const reader = new FileReader();
              reader.onload = (e) => {
                // handle the inserted image
                const dataUrl = e.target.result;
                self.imageHandler(
                  dataUrl,
                  type,
                  new ImageData(dataUrl, type, file.name)
                );
                fileInput.value = "";
              };
              reader.readAsDataURL(file);
            }
          });
        }
        fileInput.click();
      }
    },

    imageHandler: function (imageDataUrl, type, imageData) {
      let self = this;
      let imageFile = imageData.toFile();
      let formData = new FormData();
      //formData.action = '#'

      formData.append("file", imageFile);
      formData.append("isProfile", false);
      this.uploadImage(this.$backEndUrl + "/uploadImage", formData).then(
        (response) => {
          let fileName = response.fileName;
          console.log("업로드 이미지 파일명 >> ", fileName);

          let index = (self.editor.getSelection() || {}).index;
          if (index === undefined || index < 0) index = self.editor.getLength();
          self.editor.insertEmbed(
            index,
            "image",
            "/uploadImages/" + fileName,
            "user"
          );
        }
      );
    },

    uploadImage: async function (url, formData) {
      console.log(url, formData);
      let res = this.$axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
      return res;
    },

    writeArticle: function () {
      let self = this;

      if (self.title == "") {
        alert("제목을 입력해 주세요");
        return;
      }

      // eslint-disable-next-line no-constant-condition
      if(self.categorySelect == null){
        alert("카테고리를 선택해 주세요")
        return;
      }

      // 게시글 수정 로직 추기
      let isModify = false;
      if (self.id) {
        isModify = true;
      }

      // 이미지 추출
      let delta = self.editor.getContents();
      let ops = delta["ops"];
      let main_image = "";

      for (let i = 0; i < ops.length; i++) {
        if (ops[i]["insert"]["image"] != undefined) {
          console.log(ops[i]["insert"]["image"]);
          main_image = ops[i]["insert"]["image"];
          break;
        }
      }

      let data = {
        id: self.id,
        isModify: isModify,
        title: self.title,
        content: self.editor.root.innerHTML,
        content_text: self.editor.getText().replaceAll("\n", ""),
        category_id : self.categorySelect,
        main_image: main_image,
        create_date: self.createDate,
      };

      window.removeEventListener("beforeunload", this.unLoadEvent);
      this.$axios
        .post(this.$backEndUrl + "/writeArticle", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          data = response.data;

          // 임시 게시글을 작성완료한 경우 -> 임시게시글 삭제
          if (self.tempId != "") {
            self.deleteTempArticle(false);
          }
          let id = data["postId"];
          //self.$router.push("/view/" + id);
          self.$router.push({ name: "view", params: { id: id, done: true } });
        });
    },

    saveArticle: function () {
      let self = this;

      // 이미지 추출
      let data = {
        id: self.tempId,
        isModify: "",
        title: self.title,
        content: self.editor.root.innerHTML,
        content_text: self.editor.getText().replaceAll("\n", ""),
        category_id : self.categorySelect,
        main_image: "",
        create_date: "",
      };

      this.$axios
        .post(this.$backEndUrl + "/saveArticle", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          data = response.data;
          this.tempId = data["id"];
          this.modified_datetime = data["modified_datetime"];
          self.showAlertDialog(
            this.modified_datetime + " 에 임시저장 되었습니다."
          );
        });
    },
    deleteArticle: function () {
      let self = this;
      let data = {
        id: self.id,
      };

      this.$axios
        .post(this.$backEndUrl + "/deleteArticle", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          data = response.data;
          alert("삭제되었습니다.");
          window.removeEventListener("beforeunload", self.unLoadEvent);

          //self.$router.replace("/");
          self.$router.push({ name: "home", params: { done: true } });
        });
    },
    getCategory: function () {
      let self = this;
      this.$axios
        .post(this.$backEndUrl + "/getCategory", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          //console.log(response.data);
          self.categoryList = response.data["categoryList"];
        });
    },

    deleteTempArticle(isRedirect) {
      let self = this;
      let data = {
        id: self.tempId,
      };

      this.$axios
        .post(this.$backEndUrl + "/deleteTempArticle", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          data = response.data;
          console.log(data);
          if (isRedirect) {
            window.removeEventListener("beforeunload", self.unLoadEvent);
            self.$router.push({ name: "home", params: { done: true } });
          }
        });
    },
    getArticle: function (id) {
      let self = this;
      let data = {
        articleId: id,
      };
      this.$axios
        .post(this.$backEndUrl + "/getArticle", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          data = response.data;
          self.id = data.id;
          self.title = data.title;
          self.author = data.author;
          self.author_id = data.author_id;
          self.authorImage = data.author_image;
          self.content = data.content;
          self.createDate = data.create_datetime;
          self.isLiked = data.isLiked;
          self.likeCnt = data.likeCnt;
          self.viewCnt = data.viewCnt;
          self.cmtCnt = data.cmtCnt;
          
          self.categorySelect = {
            "category_id":parseInt(data.category_id),
            "category_name":data.category_name
          }
          self.editor.clipboard.dangerouslyPasteHTML(0, self.content);
        });
    },

    getTempArticle: function (id) {
      let self = this;
      let data = {
        articleId: id,
      };
      this.$axios
        .post(this.$backEndUrl + "/getTempArticle", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          data = response.data;
          self.title = data.title;
          self.author = data.author;
          self.author_id = data.author_id;
          self.authorImage = data.author_image;
          self.content = data.content;
          self.createDate = data.create_datetime;
          self.modified_datetime = data.modified_datetime;
          self.editor.clipboard.dangerouslyPasteHTML(0, self.content);
        });
    },
    showAlertDialog: function (msg) {
      if (!this.showAlert) {
        this.showAlert = true;
        this.alertMsg = msg;
        setTimeout(() => {
          this.showAlert = false;
        }, 1000);
      }
    },
  },
  mounted() {
    //alert('quill 초기화')
    //this.quill = new Quill("#editor",this.options);
    this.$emit("pageChange", "ArticleWrite");
    this.getCategory();

    if (this.$route.params.id != undefined) {
      this.id = this.$route.params.id;
      this.getArticle(this.id);
    }

    // https://egg-programmer.tistory.com/290
    // 사이트 이탈 방지
    window.addEventListener("beforeunload", this.unLoadEvent);
    if (this.$route.params.tempId != undefined) {
      this.tempId = this.$route.params.tempId;
      this.getTempArticle(this.tempId);
      console.log("임시저장게시글 불러오기");
    }
    
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.unLoadEvent);
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    isAdmin() {
      return localStorage.getItem("isAdmin") === "true";
    },
  },
};
</script>

<style>
.quill-editor iframe {
  pointer-events: none;
}
.ql-container {
  /* min-height: inherit; */
  /* font-size: 20px !important; */
  /* sticky */
  height: 100%;
  min-height: 100%;
  padding: 5px;
}
.quill-container .ql-editor {
  font-size: 18px;
  overflow-y: visible;
}
.scroll-container {
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
}
.quill-editor .ql-toolbar {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}
/* .quill-editor .ql-toolbar{
    position: fixed;
    z-index: 100;
  } */
/* 클립보드 내용 붙여넣을 경우 */
.ql-clipboard {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0px;
}
</style>
