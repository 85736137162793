`
<template>
  <v-container id="articleList" class="pt-10">
    <v-alert
      type="success"
      class="mr-5 mb-5"
      style="position: fixed; right: 0; bottom: 0; z-index: 999"
      :value="showAlert"
      transition="fade-transition"
    >
      {{ alertMsg }}
    </v-alert>
    <v-row no-gutters dense align="center" justify="center">
      <v-col md="2" xs="6" class="pr-3">
        <v-select
          v-model="categorySelect"
          :items="categoryList"
          item-text="category_name"
          item-value="category_id"
          label="Category"
          @change="onChangeCategory"
        ></v-select>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.xs" />
      <v-col md="2" xs="6" class="pl-3">
        <v-text-field
          v-model="searchKeyword"
          prepend-inner-icon="mdi-magnify"
          label="검색어 입력"
          clearable
          @keydown.enter="search"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="$vuetify.breakpoint.name == 'xs' ? 'mb-1' : 'mb-10'">
      <v-divider></v-divider>
    </v-row>
    <v-row
      dense
      v-if="searchResultToolBar"
      class="pt-5"
      no-gutters
      justify="center"
      align="center"
    >
      <strong>{{ rsltKeyword }}</strong
      >{{ searchResultText }}
      <v-btn class="ma-2" text icon color="blue" @click="resetSearch">
        <v-icon large>mdi-refresh-circle</v-icon>
      </v-btn>
    </v-row>
    <v-row
      :class="$vuetify.breakpoint.name == 'xs' ? 'd-flex' : 'mt-10 d-flex'"
      v-for="article in viewArticleList"
      :key="article.id"
      justify="center"
      align="center"
    >
      <v-col cols="12">
        <v-container>
          <v-card
            class="mx-auto transition-swing"
            elevation="12"
            max-height="350"
            :height="cardHeight"
            max-width="1000"
          >
            <v-row no-gutters>
              <v-col cols="5" class="d-flex">
                <v-img
                  @click="showArticleDetail(article.id)"
                  style="cursor: pointer"
                  max-height="350"
                  :height="cardHeight"
                  :src="article.main_image"
                > 
                  <v-chip class="ma-2" label text-color="white" x-small
                  :color="article.category_id==1?'red':article.category_id==2?'primary':'cyan'"
                  v-if="$vuetify.breakpoint.xs"
                  >
                    <v-icon left x-small> mdi-label </v-icon>
                    {{ article.category_name }}
                  </v-chip>

                  <v-chip class="ma-2" label text-color="white"
                  :color="article.category_id==1?'red':article.category_id==2?'primary':'cyan'"
                  v-else
                  >
                    <v-icon left> mdi-label </v-icon>
                    {{ article.category_name }}
                  </v-chip>
                </v-img>
              </v-col>
              <v-col cols="7">
                <v-container>
                  <v-row no-gutters v-if="!$vuetify.breakpoint.xs">
                    <v-col cols="1" v-if="!$vuetify.breakpoint.xs">
                      <div>
                        <v-avatar>
                          <img :src="article.author_image" alt="John" />
                        </v-avatar>
                      </div>
                    </v-col>

                    <v-col
                      cols="7"
                      class="ml-5"
                      align-self="center"
                      v-if="!$vuetify.breakpoint.xs"
                    >
                      <v-row dense class="font-weight-bold" no-gutters>
                        {{ article.author }}
                      </v-row>
                      <v-row dense>
                        {{ $getFormatedDateString(article.create_datetime) }}
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="1" v-if="!$vuetify.breakpoint.xs">
                      <div class="d-flex justify-center align-self-center mr-5">
                        <v-btn
                          icon
                          x-large
                          @click="like(article.id, article.isLiked)"
                        >
                          <v-icon
                            size="40"
                            :color="article.isLiked ? 'red' : 'grey'"
                            >mdi-heart</v-icon
                          >
                          <p class="text-subtitle-2 pt-4 pl-1">
                            {{ article.likeCnt == 0 ? "" : article.likeCnt }}
                          </p>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    :class="titleClass"
                    no-gutters
                    @click="showArticleDetail(article.id)"
                    style="cursor: pointer"
                  >
                    {{
                      article.title.length > 40
                        ? article.title.substring(0, 40) + "..."
                        : article.title
                    }}
                  </v-row>

                  <v-row
                    class="pl-5 pr-5 text-body-1 mt-5 d-sm-none d-xs-none d-md-flex"
                    no-gutters
                    @click="showArticleDetail(article.id)"
                    style="cursor: pointer"
                    v-if="!$vuetify.breakpoint.xs"
                  >
                    {{
                      article.title.length > 23
                        ? article.content_text.substring(0, 160)
                        : article.content_text.substring(0, 230)
                    }}
                    ...
                  </v-row>

                  <v-row
                    class="fill-height mb-1 pl-5 pr-5 mt-3"
                    no-gutters
                    align-content="center"
                  >
                    <v-col cols="12" align-self="end">
                      <v-divider></v-divider>
                      <v-row class="pt-2">
                        <v-col md="4" xs="6" align-self="end">
                          <p
                            :class="
                              $vuetify.breakpoint.name == 'xs'
                                ? 'text-caption'
                                : 'text-subtitle-2'
                            "
                          >
                            조회&nbsp; {{ article.viewCnt }}
                          </p>
                        </v-col>
                        <v-spacer v-if="!$vuetify.breakpoint.xs" />
                        <v-col
                          md="4"
                          xs="6"
                          align-self="end"
                          style="cursor: pointer"
                          @click="goToArticleComment(article.id)"
                        >
                          <p
                            :class="
                              $vuetify.breakpoint.name == 'xs'
                                ? 'text-caption'
                                : 'text-subtitle-2'
                            "
                          >
                            댓글&nbsp; {{ article.cmtCnt }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ArticleList",
  data: function () {
    return {
      articleList: [],
      viewArticleList: [],
      alertMsg: "",
      showAlert: false,
      categoryList: [],
      categorySelect: {},
      searchKeyword: "",
      searchResultToolBar: false,
      searchResultText: "",
      rsltKeyword: "",
    };
  },
  computed: {
    cardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 150;
        case "sm":
          return 250;
        case "md":
          return 350;
        case "lg":
          return 350;
        case "xl":
          return 350;
      }
      return null;
    },
    titleClass() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "pl-5 pr-5 text-h8 font-weight-bold";
      } else {
        return "pl-5 pr-5 text-h4 font-weight-bold mt-5";
      }
    },
  },
  methods: {
    onChangeCategory: function (data) {
      console.log(data, typeof data);
      this.viewArticleList = [];
      if (data == 0) {
        // 전체 게시글
        this.viewArticleList = [...this.articleList];
      } else {
        this.viewArticleList = this.articleList.filter(function (article) {
          return article["category_id"] == data;
        });
      }
    },
    tagColor(val){
      switch(val){
        case 1:
          return "indigo darken-3"
        case 2:
          return "red"
      }
    },
    resetSearch: function () {
      (this.searchKeyword = ""),
        (this.rsltKeyword = ""),
        (this.searchResultToolBar = false);
      this.getArticleList();
      this.getCategory();
    },
    search: function () {
      if (this.searchKeyword.length < 1) {
        this.showAlertDialog("검색어를 입력해 주세요");
        return;
      }
      if (this.searchKeyword.length > 10) {
        this.showAlertDialog("검색어는 10자까지 입력 가능합니다");
        return;
      }
      let self = this;
      let data = {
        keyword: this.searchKeyword,
      };
      this.$axios
        .post(this.$backEndUrl + "/getSearchArticle", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          //console.log(response.data);
          self.viewArticleList = response.data["article_list"];
          self.rsltKeyword = self.searchKeyword;
          if (self.viewArticleList.length == 0) {
            self.searchResultText = "에 대한 검색결과가 없습니다.";
          } else {
            self.searchResultText = "에 대한 검색결과";
          }
          self.searchResultToolBar = true;
        });
    },

    getCategory: function () {
      let self = this;
      this.$axios
        .post(this.$backEndUrl + "/getCategory", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          //console.log(response.data);
          self.categoryList = response.data["categoryList"];
          self.categoryList.push({
            category_id: 0,
            category_name: "전체 게시글",
          });
          self.categorySelect = {
            category_id: 0,
            category_name: "전체 게시글",
          };
        });
    },

    showArticleDetail: function (val) {
      this.$router.push("view/" + val);
    },
    getArticleList: function () {
      let self = this;
      this.$axios
        .post(this.$backEndUrl + "/getAllArticle", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          //console.log(response.data);
          self.articleList = response.data["article_list"];
          self.viewArticleList = response.data["article_list"];
        });
    },

    like: function (articleId, isLiked) {
      let self = this;

      let data = {
        articleId: articleId,
        isLike: isLiked ? false : true,
      };
      this.$axios
        .post(self.$backEndUrl + "/like", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data["isSuccess"]) {
            if (response.data["msg"] == "like") {
              self.showAlertDialog("Like+ 하였습니다!");
              self.articleList.find(function (article) {
                if (article.id == articleId) {
                  article.isLiked = true;
                  article.likeCnt += 1;
                }
              });
            } else {
              self.showAlertDialog("Like를 취소하였습니다");
              self.articleList.find(function (article) {
                if (article.id == articleId) {
                  article.isLiked = false;
                  article.likeCnt -= 1;
                }
              });
            }
          } else {
            self.showAlertDialog("서버와 통신중 에러 발생");
          }
        });
    },
    goToArticleComment: function (val) {
      this.$router.push("view/" + val + "/cmt");
    },
    showAlertDialog: function (msg) {
      if (!this.showAlert) {
        this.showAlert = true;
        this.alertMsg = msg;
        setTimeout(() => {
          this.showAlert = false;
        }, 1000);
      }
    },
  },
  mounted() {
    this.getArticleList();
    console.log(localStorage.getItem("name"));
    this.$emit("pageChange", "ArticleList");
    this.getCategory();
  },
};
</script>
`
