import Vue from "vue"
import VueRouter from "vue-router"
import articleList from "../components/ArticleList.vue"
import articleWrite from "../components/ArticleWrite.vue"
import articleView from "../components/ArticleView.vue"
import login from "../components/Login.vue"
import about from "../components/About.vue"
import axios from "axios";

Vue.use(VueRouter)

const routes = [{
    path: "/",
    name: "home",
    component: articleList,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/about",
    name: "about",
    component: about,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/write",
    name: "write",
    component: articleWrite,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/write/:id",
    name: "write",
    component: articleWrite,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/view/:id/",
    name: "view",
    component: articleView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/view/:id/:cmt",
    name: "view",
    component: articleView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/login",
    name: "login",
    component: login,
  }
]

// eslint-disable-next-line no-unused-vars
const scrollBehavior = function (to, from, savedPosition) {
  return {
    x: 0,
    y: 0
  }
}


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: scrollBehavior
})


const checkSession = function (token) {
  return new Promise(function(resolve){
    axios
    .post(Vue.prototype.$backEndUrl+"/checkToken",null,{
      headers: {
        "Authorization": token,
      },
    })
    .then((response) => {
      let data = response.data;
      if (data['isSuccess']) {
        resolve(true)
        
      } else {
        console.log(data['msg'])
        resolve(false)
      }
    });
  })

}

// 세션 체크
router.beforeEach((to, from, next) => {

  // 새로고침 방지 이벤트 리스너 제어
  if(from.name=='write'){
    console.log("is Done",to.params.done)

    if(!to.params.done){
      let isLeaveOK = confirm("변경사항이 저장되지 않을 수 있습니다.\n이동하시겠습니까?")
      if(!isLeaveOK){
        return;
      }
    }
  }else{
    next();
  }
  
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: {
          nextUrl: to.fullPath
        },
      });
    } else {
      // 세션 체크 부분
      let token = localStorage.getItem("token");
      checkSession(token).then(result=>{
        if(result){
          next();
        }else{
          next({
            path: "/login",
            params: {
              nextUrl: to.fullPath
            },
          });
        }
      })
    }
  } else {
    next();
  }
});

export default router