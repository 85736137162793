<template>
  <v-container fluid class="mt-15">
    <v-carousel
      cycle
      hide-delimiters
      :show-arrows="false"
      :height="$vuetify.breakpoint.name == 'xs' ? '160' : '400'"
      v-show="showCarousel"
    >
      <v-carousel-item  v-for="image in mainImages" :key="image" :src="image">
        <v-row class="fill-height" align="center" justify="center">
          <v-sheet
            class="mx-auto"
            color="rgba(0, 0, 0, 0.6)"
            elevation="1"
            height="200"
            rounded
            shaped
            width="700"
          >
            <v-row align="center" justify="center" class="mt-10">
              <p :class="titleClass" align="center">DGB IT 신기술 블로그</p>
            </v-row>
            <v-row align="center" justify="center">
              <p :class="subTitleClass" align="center">DGB IT R&D Center</p>
            </v-row>
          </v-sheet>
        </v-row>
      </v-carousel-item>
    </v-carousel>

    <router-view
      v-on:pageChange="onPageChange"
      v-on:loginSuccess="onLoginSuccess"
    />
  </v-container>
</template>

<script>
//import ArticleList from './ArticleList.vue';
//import ArticleWrite from './ArticleWrite.vue';

//https://www.npmjs.com/package/@morioh/v-quill-editor
export default {
  name: "Main",
  // props: ["page"],
  components: {
    //ArticleList,ArticleWrite,
  },
  computed: {
    titleClass: function () {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "text-h4 font-weight-bold white--text";
      } else {
        return "text-h2 font-weight-bold white--text";
      }
    },
    subTitleClass: function () {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "text-h8 white--text";
      } else {
        return "text-h6 white--text";
      }
    },
  },
  data: function () {
    return {
      test: false,
      mainImages: [
        "/images/main/1.jpg",
        "/images/main/2.jpg",
        "/images/main/3.jpg",
        "/images/main/4.jpg",
      ],
      showCarousel: true,
    };
  },
  methods: {
    onPageChange: function (pageName) {
      switch (pageName) {
        case "Login":
          // carousel 숨기기
          this.showCarousel = false;
          break;
        case "ArticleView":
          this.showCarousel = true;
          break;
        case "ArticleList":
          this.showCarousel = true;
          break;
        case "ArticleWrite":
          this.showCarousel = false;
          break;
        case "About":
          this.showCarousel = false;
          break;
      }
      this.$emit("pageChange", pageName);
    },
    onLoginSuccess: function (data) {
      this.$emit("loginSuccess", data);
    },
  },
  mounted() {},
};
</script>
